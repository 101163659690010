<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">实验预习考试</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="searchcontain">
        <div class="left">
          <div class="maintext">关键字</div>
          <el-input
            class="elinput"
            size="small"
            v-model="searchvalue"
            placeholder="请输入内容"
            clearable
          >
            <i class="el-icon-search el-icon_search" slot="suffix"> </i>
          </el-input>

          <div class="maintext statetext">考试状态:</div>
          <el-select size="small" v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="right">
          <div class="bt restbt">重置</div>
          <div class="bt searchbt">查询</div>
        </div>
      </div>

      <div class="main-contain">
        <div class="contain">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            class="Table"
            :header-cell-style="{
              'font-size': '15px',
              color: '#666666',
              'font-weight': 'bold',
              background: '#F7F7F7',
            }"
            :row-style="{
              'font-size': '15px',
              color: '#222222',
              'font-weight': '400',
            }"
          >
            <el-table-column prop="name" label="姓名"> </el-table-column>
            <el-table-column prop="count" label="题目数量"> </el-table-column>
            <el-table-column prop="score" label="总分数"> </el-table-column>
            <el-table-column prop="time" label="考试时长"> </el-table-column>
            <el-table-column prop="state" label="考试状态"> </el-table-column>
            <el-table-column prop="score2" label="成绩"> </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <!-- <div class="item-right"> -->
                <!-- <div class="bt">查看</div> -->
                <el-popover
                  popper-class="cuselpopover"
                  placement="bottom"
                  :visible-arrow="false"
                  trigger="click"
                >
                  <el-button class="bt" size="small" type="primary" @click="toanswer">答题</el-button>
                  <el-button class="bt" size="small" type="primary">待批阅</el-button>
                  <el-button class="bt" size="small" type="primary">已批阅</el-button>
                  <div class="bt" slot="reference" type="button">查看</div>
                  <!-- <el-button slot="reference">click 激活</el-button> -->
                </el-popover>
                <!-- <div class="line2"></div>
                  <div class="bt">下载</div> -->
                <!-- </div> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Customdialog from "@/components/customdialog.vue";

export default {
  name: "mainpage",
  components: {
    Customdialog,
  },
  data() {
    return {
      radio: "1",
      searchvalue: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      tableData: [
        {
          name: "王小虎",
          count: "10",
          score: "70",
          time: "10:20:01",
          state: "未提交",
          score2: "10",
        },
      ],
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "黄金糕",
        },
      ],
      value: "",
    };
  },
  computed: {},
  methods: {
    toback() {
      this.$router.push({
        // path: "/personalcenter/previewmaterials",
        name: "PersonalcenterMainpage",
        params: {
          id: 2,
          componentId: "Myexperiment",
        },
      });
    },
    todetail() {
      this.$router.push({
        path: "/home/personalcenter/interactiveqadetails",
      });
    },
    toanswer(){
       this.$router.push({
        path: "/home/personalcenter/answer",
      });
    }
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #288add;
      user-select: none;
      white-space: nowrap;
    }
    .retutnbt {
      margin-right: 40px;
      width: 88px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background: #26b5ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
    }
  }
  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    height: 1008px;
    background: #f7f7f7;
    border-radius: 4px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    .searchcontain {
      height: 56px;
      background: #fcfcfc;
      border: 1px solid #ececec;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .maintext {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 200px;
          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .statetext {
          margin-left: 20px;
        }
      }
      .right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .bt {
          width: 64px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #288add;
          border-radius: 2px;
          user-select: none;
          cursor: pointer;
        }
        .restbt {
          margin-right: 10px;
          background: #26b5ff;
          color: white;
        }
        .searchbt {
        }
      }
    }
    .main-contain {
      // border: 1px solid red;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      height: 200px;
      width: 100%;
      position: relative;

      .contain {
        position: absolute;
        width: 100%;
        height: 100%;
        .Table {
          width: 100%;
          // border: 1px solid red;
          .item-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            // margin-left: 10px;
            .title {
              margin-left: 10px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }
          .item-middle {
            .title {
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }
          .bt {
            //   margin-right: 10px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #26b5ff;
            user-select: none;
            cursor: pointer;
            padding: 0px;
            margin: 0px;
          }

          .item-right {
            display: flex;
            justify-content: center;
            align-items: center;
            .bt {
              //   margin-right: 10px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #26b5ff;
              user-select: none;
              cursor: pointer;
            }
            .line2 {
              margin-right: 8px;
              margin-left: 8px;
              height: 14px;
              border-right: 2px solid #cccccc;
            }
          }
        }
      }
    }
  }
}
// ::v-deep .el-popper[x-placement^="bottom"] {
//   margin-top: 0px;
// }
</style>
<style>
.cuselpopover {
  margin-top: 0px;
  min-width: 0px !important;
  width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cuselpopover .bt{
  margin-bottom: 10px;
  width: 80px;
  margin-left: 0px;
}
.el-popper[x-placement^="bottom"] {
  margin-top: 0px;
}
</style>
